<template>
    <div>
        <label class="config-header">Config: Settings</label>
        <div class="actions-description">
            All inputs on this form, including text and selections, are saved immediately after any
            changes are made.
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div v-if="!fetchInternalSettingsLoading">
                    <form @submit.prevent="" class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <label for="appIdPropertyName">Application ID Property Name</label>
                                        <div class="d-flex align-items-center mt-2">
                                            <InputText v-model="appIdPropertyName" maxlength="255"
                                                id="appIdPropertyName" class="mt-2 me-2 w-100"
                                                @focus="handleFocus($event)" @blur="handleBlur($event)"
                                                :data-property="InternalSettings.application_id_property_name"
                                                :disabled="isSaving" />
                                            <loading-icon class="d-inline ml-2"
                                                v-if="isSavingItem(InternalSettings.application_id_property_name)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="prioritizationModel">Prioritization Model</label>
                                        <br>
                                        <RadioButton v-model="prioritizationModel" class="mx-1 mt-2"
                                            inputId="prioritizationModelAdditive" value="additive"
                                            @change="updatePrioritizationModel" />
                                        <label for="prioritizationModelAdditive" class="me-1">Additive</label>

                                        <RadioButton v-model="prioritizationModel" class="mx-1 mt-2"
                                            inputId="prioritizationModelWeighted" value="weighted"
                                            @change="updatePrioritizationModel" />
                                        <label for="prioritizationModelWeighted">Weighted</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('prioritization_model')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="applicationProcessing">Application Processing</label>
                                        <br>
                                        <RadioButton v-model="applicationProcessing" class="mx-1 mt-2"
                                            inputId="applicationProcessingEnabled" value="enabled"
                                            @change="updateApplicationProcessing" />
                                        <label for="applicationProcessingEnabled" class="me-1">Enabled</label>

                                        <RadioButton v-model="applicationProcessing" class="mx-1 mt-2"
                                            inputId="applicationProcessingSuspended" value="suspended"
                                            @change="updateApplicationProcessing" />
                                        <label for="applicationProcessingSuspended">Suspended</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('application_processing')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form @submit.prevent="" class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <label for="appVetDashboardUrl">Application Vetter Dashboard URL</label>
                                        <div class="d-flex align-items-center mt-2">
                                            <InputText type="url" v-model="appVetDashboardUrl" :disabled="isSaving"
                                                :data-property="InternalSettings.appvetter_dashboard_url"
                                                maxlength="2048" @focus="handleFocus($event)" id="appVetDashboardUrl"
                                                class="mt-2 me-2 w-100" @blur="handleBlur($event)" />

                                            <a v-if="appVetDashboardUrl" :href="appVetDashboardUrl.toString()" target="_blank"
                                                title="Visit Page" class="d-inline-block" style="margin-right: 0.5em;">
                                                <i class="fa fa-external-link" aria-hidden="true"
                                                    aria-label="Visit Page"></i>
                                            </a>
                                            <loading-icon class="d-inline"
                                                v-if="isSavingItem(InternalSettings.appvetter_dashboard_url)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="betaFeature">Beta Features (Customer Level)</label>
                                        <br>
                                        <RadioButton v-model="enableBetaFeatures" class="mx-1 mt-2"
                                            inputId="betaFeatureEnable" :value="true" @change="updateBetaFeatures" />
                                        <label for="betaFeatureEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableBetaFeatures" class="mx-1 mt-2"
                                            inputId="betaFeatureDisable" :value="false" @change="updateBetaFeatures" />
                                        <label for="betaFeatureDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('enable_beta_features')" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="appVetter">Application Vetter</label>
                                        <br>
                                        <RadioButton v-model="enableAppVetter" class="mx-1 mt-2"
                                            inputId="appVetterEnable" :value="true" @change="updateAppVetter" />
                                        <label for="appVetterEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableAppVetter" class="mx-1 mt-2"
                                            inputId="appVetterDisable" :value="false" @change="updateAppVetter" />
                                        <label for="appVetterDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('application_vetter')" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form class="my-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-12">
                                        <label for="investigationServices">Investigation Services</label>
                                        <br>
                                        <RadioButton v-model="enableInvestigationServices" class="mx-1 mt-2"
                                            inputId="investigationServicesEnable" :value="true"
                                            @change="updateInvestigationServices" />
                                        <label for="investigationServicesEnable" class="me-1">Enabled</label>

                                        <RadioButton v-model="enableInvestigationServices" class="mx-1 mt-2"
                                            inputId="investigationServicesDisable" :value="false"
                                            @change="updateInvestigationServices" />
                                        <label for="investigationServicesDisable">Disabled</label>

                                        <loading-icon class="ms-2 d-inline-flex"
                                            v-if="isSavingItem('investigation_services')" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

                <div v-else>
                    <div class="card my-3" v-for="index in 6" :key="index">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <label for="skeleton">
                                        <Skeleton width="15rem" height="1rem" class="mb-2"></Skeleton>
                                    </label>
                                    <br>
                                    <div class="d-inline-flex">
                                        <Skeleton width="10rem" height="1rem" class="me-2"></Skeleton>
                                        <Skeleton width="10rem" height="1rem" class="me-2"></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import { toast } from "@/helpers/toast"
import { getApiErrorMessage } from "@/helpers/common"
import { useAPI } from "@/helpers/services/api"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue"
import { InternalSettings } from "@/helpers/common"
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import { useAdminDetails } from "@/stores/adminDetails"
import Skeleton from "primevue/skeleton"
import { useProfile } from "@/stores/profile"

const api = useAPI()
const storeAdminDetails = useAdminDetails()
const storeProfile = useProfile()
const currentCustomerAccess = computed(() => (storeProfile.getCustomerAccess))
type TypeInternalSettings = boolean | string | null

// SETTINGS
const internalSettings = computed(() => storeAdminDetails.getInternalSettings)
const prioritizationModel = ref<TypeInternalSettings>("")
const applicationProcessing = ref<TypeInternalSettings>("")
const appIdPropertyName = ref<TypeInternalSettings>("")
const appVetDashboardUrl = ref<TypeInternalSettings>("")
const enableBetaFeatures = ref<TypeInternalSettings>(null)
const enableAppVetter = ref<TypeInternalSettings>(null)
const enableInvestigationServices = ref<TypeInternalSettings>(null)

// OTHER STATE
const fetchInternalSettingsLoading = ref(true)
const currentItemOriginalValue = ref("")
const isSaving = ref(false)
const savingPropertyName = ref("")

const fetchInternalSettingsWithLoading = async () => {
    fetchInternalSettingsLoading.value = true
    await fetchInternalSettings()
    fetchInternalSettingsLoading.value = false
}

const getSettingValue = (name: string): TypeInternalSettings => (internalSettings.value.filter((s) => s.parameter === name)[0]?.value) 
const isSavingItem = (name: string) => (isSaving.value && savingPropertyName.value === name)

const fetchInternalSettings = async () => {
    await storeAdminDetails.fetchInternalSettings(loadFailureHandler)
    applicationProcessing.value = getSettingValue("application_processing")
    prioritizationModel.value = getSettingValue("prioritization_model")
    appIdPropertyName.value = getSettingValue("app_id_property_name")
    appVetDashboardUrl.value = getSettingValue("appvetter_dashboard_url")
    enableBetaFeatures.value = getSettingValue("enable_beta_features")

    enableAppVetter.value = currentCustomerAccess.value?.application_vetter as boolean
    enableInvestigationServices.value = currentCustomerAccess.value?.investigation_services as boolean
}

const updatePrioritizationModel = async () => updateSetting(InternalSettings.prioritization_model, prioritizationModel.value as InternalSettings)
const updateApplicationProcessing = async () => updateSetting(InternalSettings.application_processing, applicationProcessing.value as InternalSettings)
const updateBetaFeatures = async () => updateSetting(InternalSettings.enable_beta_features, enableBetaFeatures.value as InternalSettings)
const updateAppVetter = async () => updateSetting(InternalSettings.application_vetter, enableAppVetter.value as InternalSettings)
const updateInvestigationServices = async () => updateSetting(InternalSettings.investigation_services, enableInvestigationServices.value as InternalSettings)

const handleFocus = (event: FocusEvent) => {
    const input = event.target as HTMLInputElement
    currentItemOriginalValue.value = input.value
}

const handleBlur = (event: FocusEvent) => {
    const input = event.target as HTMLInputElement
    if (input.value !== currentItemOriginalValue.value) {
        const name = input.dataset.property
        if (!name) {
            alert("Configuration error. Element is not configured with a property name")
            return
        }
        updateSetting(name, input.value as InternalSettings)
    }
}

const updateSetting = async (name: string, value: InternalSettings) => {
    savingPropertyName.value = name
    isSaving.value = true
    const customerServices = ["application_vetter", "investigation_services"]

    try {
        if (customerServices.includes(name)) {
            const payload = {
                "application_vetter": enableAppVetter.value,
                "investigation_services": enableInvestigationServices.value
            }
            await api.patch(`/customers/`, payload)
        }
        else {
            await api.patch(`/settings/internal/${name}`, { "value": value })
        }
        await storeProfile.fetchCustomerAccess()
        toast.success("Saved!")
    } catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }

    isSaving.value = false
    savingPropertyName.value = ""
}

const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error, { "featureName": "Config: Settings" })
    toast.error(message)
}

onMounted(async () => {
    await fetchInternalSettingsWithLoading()
})

</script>
