<template>
    <div ref="appVetDataView" id="appVetDataView">
        <div class="row action-toolbar-container" id="page-header-action-section">
            <div class="col-10" v-if="!loading">
                <Button :disabled="totalRecords <= 0" v-if="!selectAllCurrentPage" id="select-all" type="button"
                    outlined class="me-2" @click="onSelectAllChange()" severity="secondary">
                    <span class="fa fa-check-square me-2" aria-hidden="true"></span> Select All
                </Button>
                <Button v-else id="deselect-all" type="button" outlined class="me-2" @click="resetToInitialState()"
                    severity="secondary">
                    <span class="fa fa-square-minus me-2" aria-hidden="true"></span> Clear Selection
                </Button>
                <Button type="button" class="me-2" @click="onStatusButtonClick('approved')"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)">
                    <span class="fa fa-check me-2" aria-hidden="true"></span> Approve
                </Button>
                <Button type="button" class="me-2" @click="onStatusButtonClick('denied')" severity="danger"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)">
                    <span class="fa fa-times me-2" aria-hidden="true"></span> Deny
                </Button>
                <Button type="button" class="me-2" @click="onStatusButtonClick('deferred')"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)" severity="secondary">
                    <span class="fa fa-clock me-2" aria-hidden="true"></span> Defer
                </Button>
                <SplitButton label="Assign" rounded class="me-2" :model="assignActionItems" id="assign-action-item"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)"
                    @click="onBulkActionClick('Assign')">
                    <span class="fa fa-user-plus me-2" aria-hidden="true"></span>
                    Assign
                </SplitButton>
                <SplitButton label="Labels" rounded class="me-2" :model="labelActionItems" id="label-action-item"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)"
                    @click="onBulkActionClick('Add Label')" severity="info">
                    <span class="fa fa-tags me-2" aria-hidden="true"></span>
                    Labels
                </SplitButton>
                <Button type="button" class="me-2" id="note-action-item" @click="onAddNoteClick" severity="info"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)">
                    <span class="fa fa-comment me-2" aria-hidden="true"></span> Note
                </Button>
                <Button type="button" class="me-2" @click="onExportClick" id="export-action-item"
                    :disabled="(dataSelection.length <= 0 && !selectAllCurrentPage)" severity="info">
                    <span class="fa fa-download me-2" aria-hidden="true"></span> Export
                </Button>
            </div>
            <div class="col-10" v-else>
                <Skeleton width="115px" height="34px" class="ms-2 mb-2"></Skeleton>
            </div>
            <div class="col-2">
                <div class="float-end">
                    <StrapCardHeaderActions :filterConfig="filterConfig" :loading="loading" :sortOrder="sortOrder"
                        @changeSortOrder="changeSortOrder" :parentDataView="appVetDataView" @updateFilters="handleUpdateFilters"
                        @submitFilters="handleSubmitFilters" @onFiltersDirtyChanged="onFiltersDirtyChanged" />
                </div>
            </div>
        </div>

        <div class="d-block text-center" id="appvet-table-header-select-all-message" v-if="selectAllCurrentPage">
            <Message severity="secondary" :closable="false">
                <span v-if="!selectAll">
                    All {{ formatNumberWithCommas(totalSelectedApplication) }} applications on this
                    page are selected.
                    <a v-if="!selectAll && (dataSelection.length < totalRecords)" href="javascript:;"
                        @click="() => selectAll = true">
                        Select all {{ formatNumberWithCommas(totalRecords) }} applications in this
                        tab
                    </a>
                </span>
                <span v-else>
                    All {{ formatNumberWithCommas(totalSelectedApplication) }} applications are
                    selected.
                    <a v-if="selectAll" href="javascript:;" @click="resetToInitialState()">
                        Clear Selection
                    </a>
                </span>
            </Message>
        </div>

        <div v-if="loading">
            <div v-for="i in 5" :key="i" class="row dataview-list-row-card">
                <div class="col-1 align-content-center" id="selectionColumn">
                    <div class="p-3">
                        <Skeleton width="100%" size="2rem" class="me-2"></Skeleton>
                    </div>
                </div>
                <div class="col align-content-center">
                    <div class="p-3">
                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="60%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col align-content-center">
                    <Skeleton width="50%" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                </div>
                <div class="col align-content-center">
                    <Skeleton width="70%" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="90%" height="1rem" class="mb-2"></Skeleton>
                </div>
                <div class="col align-content-center">
                    <div class="p-3">
                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                        <div class="d-flex mb-2">
                            <Skeleton width="50%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="30%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="80%" height="1rem" class="me-2"></Skeleton>
                        </div>
                    </div>
                </div>
                <div class="col-3 align-content-center" v-if="!isArchiveQueue">
                    <div class="p-3 d-flex">
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                    </div>
                </div>
                <div v-else class="col align-content-center">
                    <Skeleton width="10%" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="70%" height="1rem" class="mb-2"></Skeleton>
                </div>
            </div>
        </div>

        <BasicStrapCards :loading="loading" :data="data" :firstIndex="first" :rows="rows" :componentID="props.queue[0]"
            :totalRecords="totalRecords" @onpage="(event) => onPage(event)" :parentDataView="appVetDataView">
            <template #list="slotProps">
                <div v-for="(item, index) in (slotProps.data as ApplicationVetter[])" :key="index"
                    class="row dataview-list-row-card">
                    <div class="col-1 align-content-center" id="selectionColumn">
                        <div class="p-3">
                            <Checkbox v-model="dataSelection" :inputId="item.tru_id" name="category" :value="item" />
                        </div>
                    </div>
                    <div class="col align-content-center clipboard-copy-wrapper">
                        <div class="p-3">
                            <div class="mb-2">
                                <a :href="'/application-details/' + item.tru_id" target="_blank">
                                    Application {{ item.application_id }}
                                </a>
                            </div>
                            <div class="mb-2">
                                <span>
                                    Parcel {{ item.parcel_num }}
                                </span>
                                <span class="clipboard-copy-target" :id="item?.tru_id || ''"
                                    :data-copy-text="item?.parcel_num"></span>
                            </div>
                            <div class="mb-2" id="applicant">
                                {{ item.applicant }} <br>
                                {{ item.applicant_2 }}
                            </div>
                        </div>
                    </div>
                    <div class="col align-content-center">
                        <div> {{ item.exemptions?.join(", ") }} </div>
                        <div v-if="item.effective_date" class="mt-2">
                            Effective {{ formatDate(item.effective_date) }}
                        </div>
                    </div>
                    <div class="col align-content-center">
                        <div class="p-3">
                            <div v-if="item.source" class="mb-2">
                                {{ item.source }}
                            </div>
                            <div>
                                <div v-if="isArchiveQueue" class="mb-2">
                                    Submitted on {{ formatDate(item.submitted_date) }}
                                </div>
                                <div v-else class="mb-2" :title="formatDate(item.submitted_date)">
                                    <span
                                        v-if="(item._days_elapsed = dayjs().diff(item.submitted_date?.split('T')[0], 'day'))">
                                        Submitted {{
                                            item._days_elapsed.toString() + (item._days_elapsed == 1 ? " day" : " days")
                                        }} ago
                                    </span>
                                    <span v-else>Less than a day</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col align-content-center">
                        <div class="my-2">
                            <span v-if="item.assigned_user_id">
                                <Chip :label="item.assigned_user" class="me-2 mb-1 tr-chip assigned-to-chip" />
                            </span>
                            <span v-else>
                                <i>Unassigned</i>
                            </span>
                        </div>
                        <div v-if="item.labels" class="d-block mb-2">
                            <span v-for="(label, index) in item.labels" :key="index">
                                <Chip :label="label" class="me-2 mb-1 tr-chip label-chip" />
                            </span>
                        </div>
                    </div>
                    <div :class="{ 'col-3': !isArchiveQueue, 'col': isArchiveQueue }" class="align-content-center">
                        <div v-if="!isArchiveQueue" class="p-3 d-flex">
                            <ApplicationVetterStatusCheckIcon
                                :title="'Compliant Submission'"
                                :iconClass="'fa-file'"
                                :status="item.logic_check"
                            />

                            <ApplicationVetterStatusCheckIcon
                                :title="'ID Verification'"
                                :iconClass="'fa-id-card'"
                                :status="item.id_check"
                            />

                            <ApplicationVetterStatusCheckIcon
                                :title="'Applicants Match Deed'"
                                :iconClass="'fa-scroll'"
                                :status="item.deed_check"
                            />

                            <ApplicationVetterStatusCheckIcon
                                :title="'Multiple Homesteads'"
                                :iconClass="'fa-house'"
                                :status="item.multi_hs_check"
                            />

                            <ApplicationVetterStatusCheckIcon
                                :title="'Voter Registration'"
                                :iconClass="'fa-check-to-slot'"
                                :status="item.voter_check"
                            />

                            <ApplicationVetterStatusCheckIcon
                                :title="'Enhanced'"
                                :iconClass="'fa-certificate'"
                                :status="item.other_check"
                            />
                        </div>
                        <div v-else>
                            <div class="mb-2">
                                {{ ucfirst(item.application_status) }}
                                <span v-if="item.last_edited_by_username">
                                    by {{ item.last_edited_by_username || "-" }}
                                </span>
                            </div>
                            <div class="mb-2">
                                on {{ formatDate(item.last_edited_at) }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BasicStrapCards>
    </div>
    <div id="application-vetter-modals">
        <ModalDialog v-if="showUpdateStatusDialog"
            :title="`${ucfirst(bulkUpdate.statusText.value)} ${formatNumberWithCommas(totalSelectedApplication)} applications?`"
            :close="closeUpdateStatusDialog">
            <Message severity="warn" :closable="false" class="my-2" v-if="selectAllCurrentPage">
                <span v-if="!selectAll">
                    Changes will be applied to all applications on this page.
                </span>
                <span v-else>
                    Changes will be applied to all applications in this view.
                </span>
            </Message>
            <Textarea v-model="bulkUpdate.optionalNote.value" placeholder="Add note" :maxlength="MAX_NOTE_LENGTH" />
            <template #footer>
                <Button @click="submitBulkUpdateStatus(bulkUpdate.status.value)" id="bulk-update-status"
                    :disabled="bulkUpdate.loading.value">
                    <span class="fa fa-edit me-2" aria-hidden="true" v-if="!bulkUpdate.loading.value"></span>
                    <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkUpdate.loading.value"></span>
                    Submit
                </Button>
                <Button @click="closeUpdateStatusDialog" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="bulkNote.showNoteDialog?.value"
            :title="`Add note to ${formatNumberWithCommas(totalSelectedApplication)} applications`"
            :close="closeBulkNoteDialog">
            <Message severity="warn" :closable="false" class="my-2" v-if="selectAllCurrentPage">
                <span v-if="!selectAll">
                    Changes will be applied to all applications on this page.
                </span>
                <span v-else>
                    Changes will be applied to all applications in this view.
                </span>
            </Message>
            <Textarea v-model="bulkNote.note.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitBulkNote" id="bulk-note" :disabled="bulkNote.loading.value">
                    <span class="fa fa-edit me-2" aria-hidden="true" v-if="!bulkNote.loading.value"></span>
                    <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkNote.loading.value"></span>
                    Submit
                </Button>
                <Button @click="closeBulkNoteDialog" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="bulkAssign.showAssignDialog.value"
            :title="`Assign ${formatNumberWithCommas(totalSelectedApplication)} applications`"
            :close="closeAssignDialog">
            <Message severity="warn" :closable="false" class="my-2" v-if="selectAllCurrentPage">
                <span v-if="!selectAll">
                    Changes will be applied to all applications on this page.
                </span>
                <span v-else>
                    Changes will be applied to all applications in this view.
                </span>
            </Message>
            <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="bulkAssign.user.value" :options="userList" optionLabel="name"
                placeholder="-- Assign User --" class="d-flex my-2" />
            <Textarea v-model="bulkAssign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitBulkAssign('assign')" id="bulk-assign" :disabled="bulkAssign.loading.value">
                    <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!bulkAssign.loading.value"></span>
                    <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkAssign.loading.value"></span>
                    Assign
                </Button>
                <Button @click="closeAssignDialog" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="bulkAssign.showUnassignDialog.value"
            :title="`Unassign ${formatNumberWithCommas(totalSelectedApplication)} applications`"
            :close="closeAssignDialog">
            <Message severity="warn" :closable="false" class="my-2" v-if="selectAllCurrentPage">
                <span v-if="!selectAll">
                    Changes will be applied to all applications on this page.
                </span>
                <span v-else>
                    Changes will be applied to all applications in this view.
                </span>
            </Message>
            <Textarea v-model="bulkAssign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitBulkAssign('unassign')" id="bulk-unassign" :disabled="bulkAssign.loading.value"
                    severity="warning">
                    <span class="fa fa-user-times me-2" aria-hidden="true" v-if="!bulkAssign.loading.value"></span>
                    <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkAssign.loading.value"></span>
                    Unassign
                </Button>
                <Button @click="closeAssignDialog" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="bulkAssign.showAssignToMeDialog.value"
            :title="`Assign ${formatNumberWithCommas(totalSelectedApplication)} applications to me`"
            :close="closeAssignDialog">
            <Message severity="warn" :closable="false" class="my-2" v-if="selectAllCurrentPage">
                <span v-if="!selectAll">
                    Changes will be applied to all applications on this page.
                </span>
                <span v-else>
                    Changes will be applied to all applications in this view.
                </span>
            </Message>
            <Textarea v-model="bulkAssign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitBulkAssign('assigntome')" id="bulk-assign-to-me"
                    :disabled="bulkAssign.loading.value">
                    <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!bulkAssign.loading.value"></span>
                    <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkAssign.loading.value"></span>
                    Assign To Me
                </Button>
                <Button @click="closeAssignDialog" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="bulkLabel.showAddDialog.value"
            :title="`Add labels to ${formatNumberWithCommas(totalSelectedApplication)} applications`"
            :close="closeLabelDialog">
            <Message severity="warn" :closable="false" class="my-2" v-if="selectAllCurrentPage">
                <span v-if="!selectAll">
                    Changes will be applied to all applications on this page.
                </span>
                <span v-else>
                    Changes will be applied to all applications in this view.
                </span>
            </Message>
            <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
                v-model="bulkLabel.add.value" multiple optionLabel="label_name" :options="labelList" class="d-flex my-2" />
            <Textarea v-model="bulkLabel.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitBulkLabel('add')" id="add-bulk-label" :disabled="bulkLabel.loading.value">
                    <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!bulkLabel.loading.value"></span>
                    <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkLabel.loading.value"></span>
                    Add
                </Button>
                <Button @click="closeLabelDialog" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="bulkLabel.showRemoveDialog.value"
            :title="`Remove labels from ${formatNumberWithCommas(totalSelectedApplication)} applications`"
            :close="closeLabelDialog">

            <Message severity="warn" :closable="false" class="my-2" v-if="selectAllCurrentPage">
                <span v-if="!selectAll">
                    Changes will be applied to all applications on this page.
                </span>
                <span v-else>
                    Changes will be applied to all applications in this view.
                </span>
            </Message>
            <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
                v-model="bulkLabel.remove.value" multiple optionLabel="label_name" :options="labelList"
                class="d-flex my-2" />
            <Textarea v-model="bulkLabel.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
            <template #footer>
                <Button @click="submitBulkLabel('remove')" id="remove-bulk-label" severity="warning"
                    :disabled="bulkLabel.loading.value">
                    <span class="fa fa-trash me-2" aria-hidden="true" v-if="!bulkLabel.loading.value"></span>
                    <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkLabel.loading.value"></span>
                    Remove
                </Button>
                <Button @click="closeLabelDialog" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="bulkExport.showExportDialog?.value"
            :title="`Export ${formatNumberWithCommas(totalSelectedApplication)} applications?`"
            :close="closeBulkExportDialog">
            <div class="flex flex-wrap gap-3">
                <div class="flex align-items-center">
                    <RadioButton v-model="bulkExport.export.value" class="me-2" inputId="csv" value="csv" />
                    <label for="csv">Extract data as CSV file</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <RadioButton v-model="bulkExport.export.value" class="me-2" inputId="pdf" value="pdf" />
                    <label for="pdf">Download data &amp; images as PDF files</label>
                </div>
            </div>

            <template #footer>
                <Button @click="submitBulkExport" id="bulk-export" :disabled="bulkExport.loading.value">
                    <span class="fa fa-download me-2" aria-hidden="true" v-if="!bulkExport.loading.value"></span>
                    <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="bulkExport.loading.value"></span>
                    Export
                </Button>
                <Button @click="closeBulkExportDialog" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="showClearSearchConfirmation" title="Confirm Reset Filter"
            :close="() => showClearSearchConfirmation = false">
            Are you sure you want to reset all filters?
            <br>
            <br>
            This action cannot be undone, and all current search filters
            and input will be removed.

            <template #footer>
                <Button @click="resetFilter()" id="reset-filter-confirm">
                    <span class="fa fa-check-circle me-2" aria-hidden="true"></span>
                    Confirm
                </Button>
                <Button @click="showClearSearchConfirmation = false" id="reset-filter-close" severity="secondary">
                    <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
                </Button>

            </template>
        </ModalDialog>
    </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { ref, onMounted, watch, computed } from 'vue'
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"
import {
    MAX_NOTE_LENGTH,
    getApiErrorMessage,
    formatDate,
    formatNumberWithCommas,
    ucfirst,
    buildCopyToClipboardButton,
    PV_SCROLL_HEIGHT,
    DEFAULT_HOMEPAGE
} from "@/helpers/common"
import type { ApplicationVetter } from "@/helpers/interface/appvet"
import type { DataTableEvent, EventFilter, FilterObject, FilterSchema, OperatorSubstitution, FilterConfig } from "@/helpers/interface/general"
import type { Label } from "@/helpers/interface/candidates"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import { FilterMatchMode } from '@primevue/core/api'
import Select from 'primevue/select'
import Button from 'primevue/button'
import Chip from 'primevue/chip'
import SplitButton from 'primevue/splitbutton'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import { useAdminDetails } from "@/stores/adminDetails"
import type { Auth0User } from "@/helpers/interface/admin-page"
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import BasicStrapCards from "@/components/Shared/BasicStrapCards.vue"
import StrapCardHeaderActions from "@/components/Shared/StrapCardHeaderActions.vue"
import Skeleton from 'primevue/skeleton';
import { useAuth0 } from '@auth0/auth0-vue';
import { ClaimsFields } from "@/helpers/roles"
import {
    performLabelUpdate,
    performAssignAction,
    performUpdateStatusAction,
    performNoteAction,
    performExportAction,
} from "@/helpers/bulkActions"
import ApplicationVetterStatusCheckIcon from "@/components/ApplicationVetter/ApplicationVetterStatusCheckIcon.vue"
import { useProfile } from "@/stores/profile"

type StatusCode = "approved" | "denied" | "deferred"

const { user } = useAuth0();
const props = defineProps({
    locked: {
        type: String,
        required: true,
        default: 'false',
    },
    flagNamesList: {
        type: Array as () => string[],
        required: true
    },
    exemptionTypes: {
        type: Array as () => string[],
        required: true
    },
    queue: {
        type: Array as () => string[],
        required: true
    }
})
const storeProfile = useProfile()
const filterConfig = ref<FilterConfig | null>(null)
const isArchiveQueue = computed(() => props.queue.includes("approved") && props.queue.includes("denied"))
const api = useAPI()
const storeAdminDetails = useAdminDetails()
const isFilteringDirty = ref(false)
const showClearSearchConfirmation = ref<boolean>(false)
const loading = ref(true)
const loadingFilterSchema = ref(true)
const filterSchema = ref<FilterSchema[]>([])
const first = ref(0)
const rows = ref(25)
const totalRecords = ref(0)
const data = ref<ApplicationVetter[]>([])
const selectedBulkAction = ref("")
const dataParams = ref<DataTableEvent | null>(null)
const dataFiltersInitialState = {
    application_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    parcel_num: { value: null, matchMode: FilterMatchMode.CONTAINS },
    applicant: { value: null, matchMode: FilterMatchMode.CONTAINS },
    flags: { value: null, matchMode: FilterMatchMode.IN },
    tax_year: { value: null, matchMode: FilterMatchMode.IN },
    exemptions: { value: null, matchMode: FilterMatchMode.IN },
    submitted_date: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    application_status_recommendation: { value: null, matchMode: FilterMatchMode.IN },
    application_status: { value: null, matchMode: FilterMatchMode.EQUALS },
    assigned_user_id: { value: null, matchMode: FilterMatchMode.EQUALS },
}
const currentFilteredFields = computed(() => getFilterList().length - 1)
const dataFilters = ref({ ...dataFiltersInitialState })
const dataSelection = ref<ApplicationVetter[]>([])
const selectAllCurrentPage = ref(false);
const selectAll = ref(false);
const totalSelectedApplication = computed(() => {
    if (selectAll.value) {
        return totalRecords.value
    }
    if (selectAllCurrentPage.value) {
        return data.value.length
    }
    else {
        return dataSelection.value.length
    }
})
const defaultFilter = { "field": "application_status", "type": "in", "value": props.queue }
const operatorSubstitutions: OperatorSubstitution[] = [
    { type: "date", operator: ">=", text: "Is After" },
    { type: "date", operator: "<", text: "Is Before" },
]

const initialFilters = [
    {
        field: "",
        type: "",
        value: ""
    },
]

const filters = ref<FilterObject[]>(initialFilters)
const currentPage = ref(1)

const handleUpdateFilters = (updatedFilters: any) => {
    filters.value = updatedFilters
    loadData(null)
}

const handleSubmitFilters = (newFilters: any) => {
    filters.value = newFilters
    loadData(null)
}

const onSelectAllChange = (checked: boolean = true) => {
    selectAllCurrentPage.value = checked
    if (selectAll.value) {
        selectAll.value = checked
    }
    selectAllChange()
}

const selectAllChange = () => {
    if (selectAllCurrentPage.value) {
        document.querySelectorAll<HTMLInputElement>(".p-dataview-content .p-checkbox input")?.forEach((checkbox) => {
            // Disable all checkboxes
            checkbox.setAttribute("disabled", "true")
        })
        // Just to check all checkboxes for visual representation of "Select All"
        dataSelection.value = data.value
    }
    else {
        document.querySelectorAll<HTMLInputElement>(".p-dataview-content .p-checkbox input")?.forEach((checkbox) => {
            // Enable checkboxes
            checkbox.removeAttribute("disabled")
            dataSelection.value = []
        })
    }
}


const onStatusButtonClick = (newStatus: StatusCode) => {
    if (checkFiltersDirty())
        return
    bulkUpdate.status.value = newStatus
}

const onBulkActionClick = (operation: string) => {
    if (checkFiltersDirty())
        return
    selectedBulkAction.value = operation
}

const onAddNoteClick = () => {
    if (checkFiltersDirty())
        return
    bulkNote.showNoteDialog.value = true
}

const onExportClick = () => {
    if (checkFiltersDirty())
        return
    bulkExport.showExportDialog.value = true
}

const onFiltersDirtyChanged = (value: boolean) => {
    isFilteringDirty.value = value
}


const checkFiltersDirty = () => {
    if (isFilteringDirty.value) {
        toast.warning("Your filter changes have not been applied.<br>Please review your choices, and press 'Go'.", { "position": "top" })
        return true
    }
    return false
}


/*** Bulk Label ***/
const labelActionItems = [
    { label: 'Add', command: () => onBulkActionClick("Add Label") },
    { label: 'Remove', command: () => onBulkActionClick("Remove Label") },
]
const bulkLabel = {
    add: ref<Label[]>([]),
    remove: ref<Label[]>([]),
    optionalNote: ref(""),
    loading: ref(false),
    showAddDialog: computed(() => selectedBulkAction.value === "Add Label"),
    showRemoveDialog: computed(() => selectedBulkAction.value === "Remove Label"),
    parentURL: "/applications"
}
const labelList = ref<Label[]>([])
const submitBulkLabel = async (action: "add" | "remove") => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    const result = await performLabelUpdate(api, action, bulkLabel, additionalParam)
    if (result === "success") {
        loadData()
        resetToInitialState()
        closeLabelDialog()
    }
    bulkLabel.loading.value = false
}
const closeLabelDialog = () => {
    selectedBulkAction.value = ""
    bulkLabel.add.value = []
    bulkLabel.remove.value = []
    bulkLabel.optionalNote.value = ""
}

/*** Bulk Assign ***/
const assignActionItems = [
    { label: 'Assign', command: () => onBulkActionClick("Assign") },
    { label: 'Unassign', command: () => onBulkActionClick("Unassign") },
    {
        label: 'Assign to me',
        command: () => onBulkActionClick("Assign To Me"),
        disabled: !(user.value?.[ClaimsFields['app_metadata']]?.is_client)
    },
]
const bulkAssign = {
    sub: user.value?.sub,
    user: ref<Auth0User | null>(null),
    optionalNote: ref(""),
    loading: ref(false),
    showAssignDialog: computed(() => selectedBulkAction.value === "Assign"),
    showAssignToMeDialog: computed(() => selectedBulkAction.value === "Assign To Me"),
    showUnassignDialog: computed(() => selectedBulkAction.value === "Unassign"),
    parentURL: "/applications"
}
const userList = computed(() => storeAdminDetails.getUsersList)
const fetchUsersList = async () => {
    await storeAdminDetails.fetchUsersList(loadFailureHandler)
}
const submitBulkAssign = async (action: "assign" | "unassign" | "assigntome") => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    const result = await performAssignAction(api, action, bulkAssign, additionalParam)
    if (result === "success") {
        loadData()
        resetToInitialState()
        closeAssignDialog()
    }
    bulkAssign.loading.value = false
}
const closeAssignDialog = () => {
    selectedBulkAction.value = ""
    bulkAssign.user.value = null
    bulkAssign.optionalNote.value = ""
}

/*** Bulk Update Status ***/
const bulkUpdate = {
    status: ref<StatusCode | "">(""),
    optionalNote: ref(""),
    loading: ref(false),
    statusText: computed(() => {
        switch (bulkUpdate.status.value) {
            case "approved":
                return "Approve";
            case "denied":
                return "Deny";
            case "deferred":
                return "Defer";
            default:
                return "";
        }
    }),
    parentURL: "/applications"
}
const showUpdateStatusDialog = computed(() => (bulkUpdate.status.value !== ""))
const submitBulkUpdateStatus = async (status: string) => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    const result = await performUpdateStatusAction(api, status, bulkUpdate, additionalParam)
    if (result === "success") {
        loadData()
        resetToInitialState()
        closeUpdateStatusDialog()
    }
    bulkUpdate.loading.value = false
}
const closeUpdateStatusDialog = () => {
    selectedBulkAction.value = ""
    bulkUpdate.status.value = ""
    bulkUpdate.optionalNote.value = ""
}

/*** Bulk Note ***/
const bulkNote = {
    note: ref(""),
    loading: ref(false),
    showNoteDialog: ref(false),
    parentURL: "/applications"
}
const submitBulkNote = async () => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    const result = await performNoteAction(api, bulkNote, additionalParam)
    if (result === "success") {
        loadData()
        resetToInitialState()
        closeBulkNoteDialog()
    }
    bulkNote.loading.value = false
}
const closeBulkNoteDialog = () => {
    selectedBulkAction.value = ""
    bulkNote.note.value = ""
    bulkNote.showNoteDialog.value = false
}

/*** Bulk Export ***/
const bulkExport = {
    export: ref<"csv" | "pdf">("csv"),
    loading: ref(false),
    showExportDialog: ref(false),
    parentURL: "/applications"
}
const submitBulkExport = async () => {
    const additionalParam = (selectAll.value ? {
        filters: getFilterList()
    } : {
        tru_ids: dataSelection.value.map(data => data.tru_id)
    })
    const result = await performExportAction(api, props.queue, bulkExport, additionalParam)
    if (result === "success") {
        resetToInitialState()
        closeBulkExportDialog()
    }
    bulkExport.loading.value = false
}
const closeBulkExportDialog = () => {
    selectedBulkAction.value = ""
    bulkExport.export.value = "csv"
    bulkExport.showExportDialog.value = false
}


const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error, { "featureName": "Report" })
    toast.error(message)
}
const initFilters = () => {
    dataFilters.value = dataFiltersInitialState
}
const resetFilter = async () => {
    showClearSearchConfirmation.value = false
    dataParams.value = null
    resetToInitialState()
    initFilters()
    rows.value = 25
    await loadData()
}
const resetToInitialState = () => {
    dataSelection.value = []
    selectAllCurrentPage.value = false
    selectAll.value = false
    selectedBulkAction.value = ""
    selectAllChange()
}
const filterFields = ref<FilterObject[]>([])

// For Dynamic ScrollHeight Calculation
const appVetDataView = ref<HTMLElement | null>(null)

const configFilterFields = (event: any): FilterObject[] => {
    const filters = event.filters
    const filterArray: FilterObject[] = []

    const getTypeFromMatchMode = (matchMode: string): string => {
        switch (matchMode) {
            case 'contains': return 'like'
            case 'startsWith': return 'starts'
            case 'endsWith': return 'ends'
            case 'notEquals': return '!='
            case 'equals': return '='
            case 'in': return 'in'
            case 'lte': return '<='
            default: return '='
        }
    }

    for (const field in filters) {
        const filter = filters[field] as EventFilter
        if (filter.value) {
            const fieldValue = ref(filter.value)
            const fieldName = ref(field)

            // Custom
            if (field === "submitted_date") {
                const daysElapsed = () => {
                    let days = event.filters.submitted_date.value
                    let daysElapsedToDate: any = dayjs().toDate()
                    let daysValue: number = parseInt(days, 10) || 0
                    daysElapsedToDate.setDate(daysElapsedToDate.getDate() - daysValue)
                    // [daysElapsedToDate] = daysElapsedToDate.toISOString().split('T')
                    let splitResult = daysElapsedToDate.toISOString().split('T')
                    daysElapsedToDate = splitResult[0]
                    daysElapsedToDate = !days ? "" : daysElapsedToDate
                    return daysElapsedToDate
                }
                fieldValue.value = daysElapsed()
            }

            if (field === "assigned_user_id") {
                fieldName.value = "assigned_to"
            }

            // Default
            filterArray.push({
                field: fieldName.value,
                type: getTypeFromMatchMode(filter.matchMode),
                value: fieldValue.value
            })
        }
    }

    return filterArray
}


const sortOrder = ref<"desc" | "asc">("desc")
const changeSortOrder = () => {
    const queue = props.queue[0]
    const ascQueues = ["questionnaire", "unqualified", "snooze"]
    const defaultSortOrder = ascQueues.includes(queue) ? "asc" : "desc"

    if (sortOrder.value === defaultSortOrder) {
        sortOrder.value = defaultSortOrder === "asc" ? "desc" : "asc"
    }
    else {
        sortOrder.value = defaultSortOrder
    }
    loadData()
}

const configSortFields = (event: any) => {
    let sort = [{ "field": "submitted_date", "dir": sortOrder.value }]
    if (event.sortField) {
        sort = [{ "field": event.sortField, "dir": event.sortOrder == 1 ? "asc" : "desc" }]
    }
    return sort
}

const configParams = (event: any) => {
    filterFields.value = configFilterFields(event)
    filterFields.value.push(defaultFilter)
    rows.value = event?.rows
    return {
        "filter": filterFields.value,
        "page": event?.page,
        "page_size": event?.rows,
        "sort": configSortFields(event)
    }
}

const getFilterList = () => {
    const output: any = filters.value.filter(obj => obj.field && obj.type)
    output.push(defaultFilter)
    return output
}


const loadData = async (event: any = null) => {
    loading.value = true
    dataParams.value = { ...dataParams.value, page: event?.page || 1, rows: event?.rows || 25 }
    let payload = configParams(dataParams.value)

    if (filters.value.length) {
        payload.filter = getFilterList()
        payload.page = currentPage.value
    }

    try {
        const response = await api.post(`/applications/`, payload)
        data.value = response.data?.data || []
        totalRecords.value = response.data?.total || 0

        // Re-align paginator bottom elements
        realignDataTableBottomElements(`#DataView-${props.queue[0]}`)
        realignDataTableBottomElements(`#DataView-${props.queue[0]}`)

        // 
        let applicationVetterDiv = document.querySelector("#sharedSideNav .sidebar-content") as HTMLDivElement
        if (applicationVetterDiv) {
            applicationVetterDiv.style.marginBottom = totalRecords.value === 0 ? "60px" : "0px"
        }
    }
    catch (error: any) {
        data.value = []
        toast.error(getApiErrorMessage(error))
    }
    loading.value = false

    appendCopyButton()
}

const realignDataTableBottomElements = (datatableID: string) => {
    const paginatorCurrent = document.querySelector(`${datatableID} .p-paginator-current`) as HTMLElement
    const paginatorLeftContent = document.querySelector(`${datatableID} .p-paginator-left-content`) as HTMLElement
    if (paginatorLeftContent) {
        paginatorLeftContent.append(paginatorCurrent)
    }

    const paginatorDropdown = document.querySelector(`${datatableID} .p-paginator-bottom .p-dropdown`) as HTMLElement
    const paginatorRightContent = document.querySelector(`${datatableID} .p-paginator-right-content`) as HTMLElement
    if (paginatorRightContent) {
        paginatorRightContent.append(paginatorDropdown)
    }
}

const onPage = async (event: DataTableEvent) => {
    event.page += 1
    dataParams.value = event
    currentPage.value = event.page
    await loadData(event)
    selectAllChange()
    if (!selectAll.value) resetToInitialState()
}

const fetchLabelsDropdownList = async () => {
    try {
        const response = await api.get('/labels/?applications=true');
        const items = response.data ? response.data : []
        labelList.value = items as Label[]
    }
    catch (error: any) {
        labelList.value = [];
        toast.error(getApiErrorMessage(error))
    }
}

const fetchFilterSchema = async () => {
    loadingFilterSchema.value = true
    try {
        const response = await api.get('/applications/filters');
        filterSchema.value = response.data as FilterSchema[]

        filterConfig.value = {
            currentFilteredFields: currentFilteredFields.value,
            activeFilters: filters.value,
            defaultFilters: [],
            filterSchema: filterSchema.value,
            operatorSubstitutions: operatorSubstitutions
        }
    }
    catch (error: any) {
        filterSchema.value = [];
        toast.error(getApiErrorMessage(error))
    }
    loadingFilterSchema.value = false
}

const appendCopyButton = () => setTimeout(() => buildCopyToClipboardButton(), 1000)

watch(
    [currentFilteredFields, filterSchema],
    () => {

        filterConfig.value = {
            currentFilteredFields: currentFilteredFields.value,
            activeFilters: filters.value,
            filterSchema: filterSchema.value,
            operatorSubstitutions: operatorSubstitutions
        }
    },
    { deep: true }
)

onMounted(async () => {
    // document.addEventListener('click', handleClickOutside)
    dataParams.value = {
        first: 0,
        rows: 25,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: dataFilters.value
    }
    initFilters()
    await loadData()
    fetchFilterSchema()
    fetchLabelsDropdownList()
    fetchUsersList()
})
</script>

<style>
@import "@/assets/appvet.css";
</style>
