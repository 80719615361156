<template>
    <div id="filter-component">

        <!-- Predefined / Default Filters -->
        <div v-if="defaultFilters?.length && props.showDefaultFilters">
            <div class="row g-3 filter-group" v-for="(item, index) in defaultFilters" :key="index">
                <div class="col-auto">
                    <Button severity="secondary" disabled
                        :title="filters.length > 1 ? 'Remove Filter' : 'Clear Filter'">
                        <span class="fa fa-times-circle" aria-hidden="true"></span>
                    </Button>
                </div>

                <!-- field name  -->
                <div class="col-auto">
                    <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.field" :options="filterSchema" optionLabel="display_name"
                        optionValue="field_name" placeholder="Filter by" class="w-100" id="filter-by" disabled />

                </div>

                <!-- operator -->
                <div class="col-auto">
                    <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.type" :options="defaultFilterOperator" optionLabel="label" optionValue="value"
                        disabled placeholder="Filter type" class="w-100" id="filter-type" />
                </div>


                <!-- value -->
                <div class="col-auto">
                    <InputText type="text" v-model="item.value" :value="ucfirst(getQueueText(item.value))" disabled
                        id="filter-value" class="w-100" />
                </div>
            </div>
        </div>

        <!-- User-Specified Filter Criteria -->
        <div class="row g-3 filter-group" v-for="(item, index) in filterInfo" :key="index">
            <div class="col-auto">
                <Button severity="secondary" @click="removeFilter(index)"
                    :title="filters.length > 1 ? 'Remove Filter' : 'Clear Filter'">
                    <span class="fa fa-times-circle" aria-hidden="true"></span>
                </Button>
            </div>

            <!-- field name  -->
            <div class="col-auto">
                <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.filter.field" :options="fieldListItems" optionLabel="display_name"
                    optionValue="field_name" placeholder="Filter by" class="w-100"
                    @change="onChangeFilterBy(item.filter)" id="filter-by" />
            </div>

            <!-- operator -->
            <div class="col-auto">
                <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.filter.type" :options="item.operators" optionLabel="label" optionValue="value"
                    placeholder="Filter type" class="w-100" @change="onChangeFilterType(item.filter)"
                    id="filter-type" />
            </div>

            <!-- value -->
            <div class="col-auto">

                <div v-if="(lookupDataLoading == item.fieldName)">
                    <Skeleton height="2.2rem" width="200px"></Skeleton>
                </div>

                <!-- Possible Values / Lookup URL-->
                <template v-else-if="item.possibleValues.length > 0">
                    <template v-if="multiSelectOperators.includes(item.filter.type)">
                        <MultiSelect :show-toggle-all="false" placeholder="-- Select --" display="chip" required
                            v-model="item.filter.value" multiple optionLabel="text" optionValue="id"
                            :options="item.possibleValues" class="d-flex" id="filter-value" @change="onValueChange" />
                    </template>
                    <template v-else>
                        <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.filter.value" :options="substituteNullIDsHack(item.possibleValues)"
                            placeholder="-- Select --" class="w-100" optionLabel="text" optionValue="id"
                            id="filter-value" @change="onValueChange" />
                    </template>
                </template>

                <template v-else>
                    <template v-if="item.schema.type === 'date'">
                        <DatePicker v-model="item.filter.value" :disabled="item.disableValueElement" id="filter-value"
                            class="w-100" date-format="m/d/yy" @update:model-value="onValueChange" />
                    </template>

                    <template v-else-if="item.schema.type === 'bool'">
                        <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="item.filter.value" :options="boolTrueFalseOption"
                            :disabled="item.disableValueElement" optionLabel="label" optionValue="value"
                            placeholder="-- Select --" class="w-100" id="filter-value" @change="onValueChange" />
                    </template>

                    <template v-else>
                        <InputText type="text" v-model="item.filter.value" :disabled="item.disableValueElement"
                            @keyup.enter="submitFilter" id="filter-value" class="w-100" @change="onValueChange" />
                    </template>
                </template>
            </div> <!-- end value options -->

            <div class="col-auto">
                <div :class="{ 'invisible': index + 1 !== filters.length }" class="d-inline-flex text-start">
                    <Button severity="info" @click="addFilter" class="" title="Add Filter">
                        <span class="fa fa-plus-circle" aria-hidden="true"></span>
                    </Button>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col text-right">
                <Button severity="info" @click="submitFilter" class="" title="Submit Filter" id="submit-filter">
                    <span class="fa fa-search me-2"></span> Go
                </Button>
            </div>
        </div>
    </div>
</template>

<style>
#filter-component #filter-by,
#filter-component #filter-type,
#filter-component #filter-value {
    width: 200px !important;
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue'
import InputText from 'primevue/inputtext'
import Select from 'primevue/select'
import DatePicker from 'primevue/datepicker'
import Button from 'primevue/button'
import MultiSelect from 'primevue/multiselect'
import Skeleton from 'primevue/skeleton'
import type {
    FilterSchema,
    FilterFields,
    Operator,
    OperatorSubstitution,
    OperatorExclusion,
    GenericKeyValueItem,
    FilterObject
} from "@/helpers/interface/general"
import dayjs from 'dayjs'
import { allFilterOperator, getApiErrorMessage, ucfirst, getQueueText, PV_SCROLL_HEIGHT } from '@/helpers/common'
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"

// https://github.com/trueroll/TrueVue/issues/1135
const SUB_NULL_PRIMEVUE_VALUE = "{{SubstituteNull}}"

const api = useAPI()
const props = defineProps<{
    activeFilters: FilterObject[],
    defaultFilters: FilterFields[] | undefined,
    filterSchema: FilterSchema[],
    operatorSubstitutions?: OperatorSubstitution[],
    operatorExclusions?: OperatorExclusion[],
    fieldExclusions?: string[],
    showDefaultFilters?: Boolean
}>()
const emit = defineEmits(["submitFilters", "updateFilters", "isDirty"])

const defaultFilterType: Operator[] = ["ilike", "starts", "="]
const filterSchema = ref<FilterSchema[]>(props.filterSchema)
const filterInitialState = {
    field: "",
    type: "",
    value: ""
}
const defaultFilterOperator = computed(() => {
    return getAvailableOperators({
        field_name: "",
        type: "str",
        display_name: '',
        operators: null
    }, true)
})
const defaultFilters = props.defaultFilters
const filters = ref<FilterFields[]>(props.activeFilters)

interface FilterInfo {
    fieldName: string;
    filter: FilterFields;
    schema: FilterSchema;
    disableValueElement: boolean;
    possibleValues: any[];
    operators: any[];
}

const filterInfo = computed(() => {
    return filters.value?.map(filter => {
        const schema = getSchema(filter) || { field_name: filter.field, type: "str" } as FilterSchema
        const output: FilterInfo = {
            fieldName: filter.field,
            filter: filter,
            schema: schema,
            disableValueElement: noValueOperators.includes(filter.type),
            possibleValues: getPossibleValues(schema),
            operators: getAvailableOperators(schema)
        }

        return output
    })
})

const fieldListItems = computed(() => {
    const exclusions = props.fieldExclusions || []
    return filterSchema.value.filter(x => !exclusions.includes(x.field_name))
})

const boolTrueFalseOption = [
    {
        value: "true",
        label: "Yes"
    },
    {
        value: "false",
        label: "No"
    }
]

const noValueOperators = ["is null", "is not null"]
const multiSelectOperators = ["in", "not in", "has all"]

const lookupDataLoading = ref<string | null>(null)


const getSchema = (filter: FilterFields) => {
    return filterSchema.value.find(f => f.field_name === filter.field)
}

const onChangeFilterBy = async (filter: FilterFields) => {
    emit("isDirty", true)
    const schema = getSchema(filter)
    filter.type = schema?.default_operator || ""
    filter.value = ""
    if (schema && schema.lookup_url) {
        await fetchLookupData(schema)
    }
}

const onChangeFilterType = (filter: FilterFields) => {
    if (![
        ...defaultFilterType,
        ">", ">=", "<", "<=", "!=", "is null", "is not null"
    ].includes(filter.type)) {
        filter.value = ""
        emit("isDirty", true)
    }
}

const onValueChange = () => {
    emit("isDirty", true)
}

const removeFilter = (index: number) => {
    if (filters.value.length <= 1) {
        filters.value = [{ ...filterInitialState }]
        submitFilter()
    } else {
        filters.value.splice(index, 1)
        emit("isDirty", true)
    }
    emit('updateFilters', filters.value)
}


const getPossibleValues = (field: FilterSchema): GenericKeyValueItem[] => {
    return field && field.possible_values ? field.possible_values.map(value => {
        if (typeof value === "string") {
            return {
                text: ucfirst(value),
                id: value,
            }
        } else if (typeof value === "number") {
            return {
                text: value.toString(),
                id: value.toString()
            }
        } else if (typeof value === "object") {
            return {
                text: value.text,
                id: value.id
            }
        } else {
            return { text: value, id: value }
        }
    }) : []
}

const fetchLookupData = async (schema: FilterSchema) => {
    if (!schema || !schema.lookup_url) {
        return
    }

    // if we've already loaded data for this field, then exit
    if (schema.possible_values && schema.possible_values.length > 0) {
        return
    }

    lookupDataLoading.value = schema.field_name

    try {
        const response = await api.get(schema.lookup_url)
        let data = response.data

        if (data && data.length > 0 && typeof data[0] === "string") {
            data = data.map((x: string) => { return { "id": x, "text": x } })
        }
        schema.possible_values = data
    }
    catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }
    lookupDataLoading.value = null
}


const getFieldType = (fieldName: string): string => {
    const field = filterSchema.value.find(f => f.field_name === fieldName)
    if (field?.lookup_url) {
        return field.type
    }
    else return transformFieldType(field ? field.type : 'str')
}

const transformFieldType = (type: string): string => {
    switch (type) {
        case "str":
            return "text"
        case "date":
            return "date"
        default:
            return type
    }
}
const getAvailableOperators = (schema: FilterSchema, showAll: boolean = false): any => {
    let ops = schema?.operators || (!showAll ? defaultFilterType : allFilterOperator)

    const substitutions = props.operatorSubstitutions || []
    const exclusions = props.operatorExclusions || []

    if (exclusions.length) {
        ops = ops.filter((operator: Operator) => {
            const possible_exc = exclusions.filter(exc => exc.operator === operator)
            const match = possible_exc.find(exc => {
                return (
                    (exc.field === schema.field_name && !exc.type)
                    || (exc.type === schema.type && !exc.field)
                    || (exc.field === schema.field_name && exc.type === schema.type)
                )
            })
            return !match
        })
    }

    return ops.map((operator: Operator) => {
        const possible_subs = substitutions.filter(sub => sub.operator === operator)
        const match = possible_subs.find(sub => {
            return (
                (sub.field === schema.field_name && !sub.type)
                || (sub.type === schema.type && !sub.field)
                || (sub.field === schema.field_name && sub.type === schema.type)
                || (!sub.field && !sub.type)
            )
        })
        return {
            value: operator,
            label: match ? match.text : operatorMapping[operator]
        }
    })
}

const operatorMapping: { [key in Operator]: string } = {
    "=": "Is Exactly",
    "!=": "Is Not Exactly",
    ">": "Is Greater Than",
    ">=": "Is Greater Than Or Equal To",
    "<": "Is Less Than",
    "<=": "Is Less Than Or Equal To",
    "is null": "Is Empty",
    "is not null": "Is Not Empty",
    "in": "Includes Any",
    "not in": "Does Not Include",
    "like": "Contains",
    "not like": "Does Not Contain",
    "ilike": "Contains",
    "not ilike": "Does Not Contain",
    "starts": "Starts With",
    "ends": "Ends With",
    "~*": "Matches",
    "has all": "Includes All",
}


const addFilter = () => {
    filters.value.push({ ...filterInitialState })
    emit("isDirty", true)
}


// https://github.com/trueroll/TrueVue/issues/1135
const substituteNullIDsHack = (items: GenericKeyValueItem[] | string[]) => {

    const l = items.length;
    if (!l || typeof items[0] === "string") {
        return;
    }
    const kvItems = [...items] as GenericKeyValueItem[]
    for (let i = 0; i < l; i++) {
        if (kvItems[i].id === null) {
            kvItems[i].id = SUB_NULL_PRIMEVUE_VALUE
        }
    }
    return kvItems
}


const submitFilter = () => {
    const filteredArray = ref<FilterFields[]>([])
    filteredArray.value = JSON.parse(JSON.stringify(filters.value))
    filteredArray.value = filteredArray.value.filter(obj => {

        // Format Date
        if (getFieldType(obj.field) === 'date') {
            if (dayjs(obj.value).isValid()) {
                obj.value = dayjs(obj.value).format('YYYY-MM-DD')
            } else {
                obj.value = ""
                return false
            }
        }

        if (["=", "!="].includes(obj.type)) {
            if (obj.value === null) {
                obj.type = obj.type === "=" ? "is null" : "is not null"
                obj.value = obj.type
            }
        }

        // Removing from payload body
        if (obj.type === "in") {
            if (obj.value === "" || obj.value?.length === 0) {
                return false
            }
        }

        // https://github.com/trueroll/TrueVue/issues/1135
        if (obj.value === SUB_NULL_PRIMEVUE_VALUE) {
            obj.type = "is null"
            obj.value = "is null"
        }

        if (!obj.field || !obj.type) return false

        return true
    })
    emit('submitFilters', filteredArray.value)
    emit("isDirty", false)
}

const clearFilters = () => {
    filters.value = [{ ...filterInitialState }]
}

defineExpose({
    clearFilters
})
</script>

<style scoped>
.filter-group {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}
</style>