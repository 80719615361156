import { ref, computed } from "vue"
import { defineStore } from "pinia";
import { useAPI } from "@/helpers/services/api"
import type { Customer } from "@/helpers/interface/admin-page"
import { StorageKeyEnum, storageManager } from "@/helpers/storageManager";
import { validateUserPermission } from "@/helpers/common";

export const useProfile = defineStore("profile", () => {

    const api = useAPI()
    const customerAccess = ref<Customer>()
    const userPermissions = ref<string[]>([])
    const userPermissionsLoadingState = ref(false)

    const fetchCustomerAccess = async () => {
        userPermissionsLoadingState.value = true
        await api.get("/customers/current")
            .then(response => {
                customerAccess.value = response.data as Customer
            })
            .catch(() => {
                customerAccess.value = { customer_id: "", name: "-" }
            })
            .finally(() => userPermissionsLoadingState.value = false)
    }

    const fetchUserPermissions = async () => {
        userPermissionsLoadingState.value = true
        await api.get("/users/auth0/permissions")
            .then(response => {
                userPermissions.value = response.data
            })
            .finally(() => userPermissionsLoadingState.value = false)
    }

    const getCustomerAccess = computed(() => customerAccess.value)
    const getCurrentPermissions = computed(() => userPermissions.value)
    const enableBetaFeatures = computed(() => {
        storageManager.setItem(StorageKeyEnum.EnableBetaFeaturesCL, customerAccess.value?.enable_beta_features ? "on" : "off")
        if (customerAccess.value?.enable_beta_features) return true
        
        if (storageManager.getItem(StorageKeyEnum.EnableBetaFeatures) === "on") return true
        return false
    })

    const investigationServicesEnabled = computed((): boolean => customerAccess.value?.investigation_services || validateUserPermission("read", "customers"))

    return {
        customerAccess,
        userPermissions,
        userPermissionsLoadingState,
        fetchCustomerAccess,
        fetchUserPermissions,
        getCustomerAccess,
        getCurrentPermissions,
        enableBetaFeatures,
        investigationServicesEnabled
    }

})
